import {
    Alert,
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Form,
    Input,
    notification,
    Select,
    Spin,
    theme,
    Typography,
    Upload
} from "antd";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {AimOutlined, HomeOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import place from "../../assets/place.png";
import {useEffect, useState} from "react";
import {MapContainer, TileLayer, Marker, useMapEvents, useMap} from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import TextArea from "antd/es/input/TextArea";
import axios from "../../axios_conf";

const { useToken } = theme;

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
const AddBusiness = ({user, setAuthStateChanged}) => {
    const token = useToken()
    const [mapPosition, setMapPosition] = useState([41.99646, 21.43141]);
    const [banner, setBanner] = useState(null);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(true)
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const MapHelper = () => {
        const map = useMapEvents({
            drag: (e) => {
                const center = e.target.getCenter();
                setMapPosition([center.lat,center.lng])
            },
            zoom:(e) => {
                const center = e.target.getCenter();
                setMapPosition([center.lat,center.lng])
            }
        });
        return null;
    }

    useEffect(()=>{
        setLoadingData(true)
        const param = searchParams.get('id')
        if(param != null){
            axios.get("/Businesses/"+param).then(res=>{
                form.setFieldsValue(res.data);
                const mapPos = [res.data.lat, res.data.lng]
                setMapPosition(mapPos)
                if(res.data.photoURL.length>0) {
                    setFileList([
                        {
                            uid: '1',
                            name: 'image.png',
                            status: 'done',
                            url: res.data.photoURL,
                        },
                    ])
                }
                setLoadingData(false)
            })
        }else{
            setLoadingData(false)
        }
    },[searchParams])
    const uploadBanner = (file) => {
        setBanner(file)
        return false
    }

    const removeBanner = () => {
        setBanner(null)
    }

    const finishForm = async (data) => {
        let photoUrl = ""
        try{
            photoUrl = await toBase64(data.photoUrl.file)
        }
        catch{
            if(fileList.length>0){
                photoUrl = fileList[0].url
            }
        }
        const fullData = {
            ...data,
            photoUrl: photoUrl,
            lat: mapPosition[0],
            lng: mapPosition[1]
        }
        setLoading(true)
        const param = searchParams.get('id')
        if(param != null) {
            axios.put("/Businesses/"+param, fullData).then(res=>{
                setAuthStateChanged(auth => auth+1)
                navigate("/dashboard")
                setLoading(false)
            }).catch(()=>{
                api['error']({
                    message: 'An error occurred',
                    description:
                        'Please try again later!',
                });
                setLoading(false)
            })
            return;
        }
        axios.post("/Businesses/", fullData).then(res=>{
            setAuthStateChanged(auth => auth+1)
            navigate("/dashboard")
            setLoading(false)
        }).catch(()=>{
            api['error']({
                message: 'An error occurred',
                description:
                    'Please try again later!',
            });
            setLoading(false)
        })
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    return (
        <div style={{textAlign: 'start', padding: '20px'}}>
            {contextHolder}
            <Breadcrumb
                items={[
                    {
                        title: <Link to="/dashboard"><HomeOutlined/></Link>,
                    },
                    {
                        title: <Link to="/dashboard">My businesses</Link>,
                    },
                    {
                        title:  searchParams.get('id')==null ? "Add Business" : "Edit business"
                    },
                ]}
            />
            <Typography.Title level={2} style={{margin: '10px 0'}}>{searchParams.get('id')==null ? "Add a new Business" : "Edit business"}</Typography.Title>
            <br/>
            <div>
                {loadingData ? <Spin size={'large'}/> :
                    <Card>
                <Form layout={'vertical'} onFinish={finishForm} form={form}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Name is required',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[
                            {
                                required: true,
                                message: 'Address is required',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="City"
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: 'City is required',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name='description'
                        help={'Include working hours or any additional information'}>
                        <TextArea rows={3} />
                    </Form.Item>
                    <Form.Item
                        label="Default currency"
                        name='defaultCurrency'
                        style={{width:'300px'}}
                        rules={[
                            {
                                required: true,
                                message: 'Default currency is required',
                            },
                        ]}>
                        <Select>
                            <Select.Option value="EUR">EURO</Select.Option>
                            <Select.Option value="USD">DOLLAR</Select.Option>
                            <Select.Option value="DEN">DENAR</Select.Option>
                            <Select.Option value="YEN">YEN</Select.Option>
                            <Select.Option value="DIN">DINAR</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="photoUrl"
                        label="Banner"
                        help={'Preferred resolution - 2000x1000'}
                    >
                        <Upload name="photoUrl" maxCount={1} listType="picture-card" beforeUpload={uploadBanner} onRemove={removeBanner} accept={'.png,.jpg,.jpeg'} fileList={fileList} onChange={(newFileList)=>setFileList(newFileList.fileList)}>
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        </Upload>
                    </Form.Item>
                    <div style={{margin:'20px',marginLeft:'0px'}}>
                        <Typography.Title level={5}>Exact location</Typography.Title>
                        <Typography.Text>Move the map until the center shows the exact location of your business</Typography.Text>
                        <br/>
                        <br/>
                        <MapContainer center={mapPosition} zoom={4} scrollWheelZoom={false}>
                            <AimOutlined style={{position: 'absolute', top:'calc(50% - 20px)', zIndex: 2323123, left:'calc(50% - 20px)', fontSize: '40px',}}/>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url={token.theme.id==1 ? "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" :"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                            />
                            <RecenterAutomatically lat={mapPosition[0]} lng={mapPosition[1]}/>
                            <MapHelper/>
                        </MapContainer>
                    </div>
                    {user.plan === 0 ?
                        <Alert
                            message="TasteMate AI is not available on the free plan."
                            type="info"
                            action={
                                <Link to={"/contact"}>
                                    Upgrade your plan!
                                </Link>
                            }
                            showIcon
                        />
                        :
                        <></>
                    }
                    <Form.Item name="activeAiAssistant" valuePropName="checked" help={'Enable TasteMate, the AI assistant that provides personalized food recommendations to your customers! '}>
                        <Checkbox disabled={user.plan === 0}>Enable AI Assistant</Checkbox>
                    </Form.Item>
                    <Form.Item name="activeOrders" valuePropName="checked" help={'Enable customers to make orders for this restaurant (Not yet available)'}>
                        <Checkbox disabled={true}>Enable Orders</Checkbox>
                    </Form.Item>
                    <Form.Item name="activeReservations" valuePropName="checked"  help={'Enable customers to make reservations for this restaurant (Not yet available)'}>
                        <Checkbox disabled={true}>Enable Reservations</Checkbox>
                    </Form.Item>
                    <br/>
                    <Form.Item
                        label="Phone"
                        name="contactNum"
                        help={"Please also include the country code"}
                    >
                        <Input placeholder='ex. +49 30901820'/>
                    </Form.Item>
                    <Form.Item
                        label="Facebook url"
                        name="contactFacebook"
                        rules={[{type: 'url', message: "Please enter a valid URL (include https://)"}]}
                    >
                        <Input placeholder='ex. https://facebook.com/cafe'/>
                    </Form.Item>
                    <Form.Item
                        label="Instagram url"
                        name="contactInstagram"
                        rules={[{type: 'url', message: "Please enter a valid URL (include https://)"}]}
                    >
                        <Input placeholder='ex. https://instagram.com/cafe'/>
                    </Form.Item>
                    <Form.Item
                        label="Twitter url"
                        name="contactTwitter"
                        rules={[{type: 'url', message: "Please enter a valid URL (include https://)"}]}
                    >
                        <Input placeholder='ex. https://twitter.com/cafe'/>
                    </Form.Item>
                    <Form.Item
                        label="Additional url"
                        name="contactURL"
                        rules={[{type: 'url', message: "Please enter a valid URL (include https://)"}]}
                    >
                        <Input placeholder='ex. https://example.com/'/>
                    </Form.Item>
                    <Form.Item style={{textAlign:'center'}}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
                    </Card>
                }
            </div>
        </div>
    )
}

const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
    useEffect(() => {
        map.setView([lat, lng]);
    }, [lat, lng]);
    return null;
}

export default AddBusiness;