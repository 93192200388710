import axios from "axios";

const instance = axios.create({
    baseURL: 'https://alfamenu.azurewebsites.net/api',
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Authorization': localStorage.getItem("JWT")
    }
})

instance.interceptors.request.use(config => {
    config.headers.Authorization = localStorage.getItem("JWT");
    return config;
}, error => {
    return Promise.reject(error);
});

export default instance;
