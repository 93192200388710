import React from 'react';
import { Card, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import contactpng from '../../assets/contact.png';
import Header from "../Header/Header";
import {Helmet} from "react-helmet";

const { Text } = Typography;

const ContactPage = ({user, toggleTheme, setAuthStateChanged}) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact us | αMenu</title>
            </Helmet>
            <Header user={user} toggleTheme={toggleTheme} setAuthStateChanged={setAuthStateChanged}/>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Card style={{ width: 300, textAlign: 'center' }}>
                    <img
                        src={contactpng}
                        alt="Contact Graphic"
                        style={{ width: '100%', height: 'auto', marginBottom: 16 }}
                    />
                    <Text strong>Email: </Text>
                    <Text style={{ marginBottom: 8 }}>
                        <a href="mailto:contact@alfamenu.bar">contact@alfamenu.bar</a>
                    </Text>
                    <br/>
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        For plan upgrades, questions, or any other inquiries, please feel free to contact us.
                    </Text>
                </Card>
            </div>
        </div>
    );
};

export default ContactPage;
