import {Button, Card, Checkbox, Form, Input, notification, Space, Typography} from "antd";
import '../../App.css'
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import axios from "../../axios_conf";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const Register = ({updateUser}) => {
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const onFinish = (values) => {
        console.log(values)
        setLoading(true)
        axios.post("/Users", values).then(res=>{
            localStorage.setItem("JWT", "Bearer "+res.data);
            updateUser(Math.random())
            navigate("/auth/verify")
        }).catch(res => {
            api['error']({
                message: 'Error',
                description: res,
            });
            setLoading(false)
        })
    };
    return(
        <div style={{width:'100vw',height:'100vh'}}>
            {contextHolder}
            <Card style={{borderRadius:'20px'}} className='center'>
                <div style={{textAlign:'center'}}>
                    <Typography.Title level={1}>Register</Typography.Title>
                </div>
                <Form
                    name="normal_register"
                    className="register-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your Email!' },  {type: 'email', message: 'Please input a valid email!'}]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value.length>=8) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Your password should be at least 8 characters'));
                                },
                            }),
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />}/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Confirm Password" prefix={<LockOutlined className="site-form-item-icon" />}/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{width:'100%'}} className="login-form-button" loading={loading}>
                            Register
                        </Button>
                        Or <a href="/auth/login">login now!</a>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default Register;