import React, {useEffect, useState} from 'react'
import {Menu, Dropdown, theme, Space, Typography} from 'antd'
import {
    BulbOutlined,
    ContainerOutlined,
    TeamOutlined,
    LineChartOutlined,
    BookOutlined,
    DeploymentUnitOutlined,
    DownOutlined,
    UserOutlined,
    ControlOutlined,
    ClockCircleOutlined,
    SettingOutlined,
    ArrowsAltOutlined
} from '@ant-design/icons'
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import Header from "../Header/Header";
import {Helmet} from "react-helmet";

const {useToken} = theme;

const Dashboard = ({user, toggleTheme, setAuthStateChanged}) => {
    
    const [selectedKey, setSelectedKey] = useState('1');
    const [miniMenu, setMiniMenu] = useState(true)
    const {token} = useToken();

    const location = useLocation();
    useEffect(() => {
        if (location.pathname.split('/').length > 2) {
            const page = location.pathname.split('/')[2]
            if (page === 'menu') setSelectedKey('2')
            if (page === 'orders') setSelectedKey('3')
            if (page === 'reservations') setSelectedKey('4')
            if (page === 'loyalty') setSelectedKey('5')
            if (page === 'events') setSelectedKey('6')
            if (page === 'employees') setSelectedKey('7')
            if (page === 'analytics') setSelectedKey('8')
            if (page === 'tables') setSelectedKey('9')
            if (page === 'settings') setSelectedKey('10')
        }
        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        if (width > 1000) setMiniMenu(false);
    }, [])

    let items;
    if (user.ownedBusinesses.length > 0) {
        items = user.ownedBusinesses.map(el => {
            return {key: el.id, label: el.name}
        });
    } else {
        items = [{key: '1', label: 'Add your first business to show up here', disabled: true}];
    }

    return (
        <div style={{height: '100vh'}}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard | αMenu</title>
            </Helmet>
            <Header user={user} toggleTheme={toggleTheme} setAuthStateChanged={setAuthStateChanged}/>
            <div style={{width: '15%', display: 'inline-block', verticalAlign: 'top',}}>
                <Menu
                    style={{
                        width: '100%',
                        height: '85vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        backgroundColor: token.colorPrimaryBg
                    }}
                    selectedKeys={[selectedKey]}
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    inlineCollapsed={miniMenu}
                    disabled={user.ownedBusinesses.length === 0}

                >
                    <Menu.Item onClick={() => setSelectedKey('1')}
                               key="1"
                               icon={<BulbOutlined/>}>
                        <Link to="/dashboard">Businesses</Link>
                    </Menu.Item>
                    <Menu.Item onClick={() => setSelectedKey('2')} key="2" icon={<ContainerOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/menu">Menu</Link></Menu.Item>
                    <Menu.Item style={{pointerEvents: 'none', color: 'gray'}} onClick={() => setSelectedKey('9')}
                               key="9" icon={<ArrowsAltOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/tables">Tables</Link></Menu.Item>
                    <Menu.Item style={{pointerEvents: 'none', color: 'gray'}} onClick={() => setSelectedKey('3')}
                               key="3" icon={<TeamOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/orders">Orders</Link></Menu.Item>
                    <Menu.Item style={{pointerEvents: 'none', color: 'gray'}} onClick={() => setSelectedKey('4')}
                               key="4" icon={<ClockCircleOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/reservations">Reservations</Link></Menu.Item>
                    <Menu.Item style={{pointerEvents: 'none', color: 'gray'}} onClick={() => setSelectedKey('5')}
                               key="5" icon={<BookOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/loyalty">Loyalty program</Link></Menu.Item>
                    <Menu.Item style={{pointerEvents: 'none', color: 'gray'}} onClick={() => setSelectedKey('6')}
                               key="6" icon={<DeploymentUnitOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/events">Events</Link></Menu.Item>
                    <Menu.Item style={{pointerEvents: 'none', color: 'gray'}} onClick={() => setSelectedKey('7')}
                               key="7" icon={<UserOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/employees">Employees</Link></Menu.Item>
                    <Menu.Item style={{pointerEvents: 'none', color: 'gray'}} onClick={() => setSelectedKey('8')}
                               key="8" icon={<LineChartOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/analytics">Analytics</Link></Menu.Item>
                    <Menu.Item onClick={() => setSelectedKey('10')} key="10" icon={<SettingOutlined/>}><Link
                        style={{pointerEvents: user.ownedBusinesses.length === 0 ? 'none' : ''}}
                        to="/dashboard/settings">Settings</Link></Menu.Item>
                </Menu>
            </div>
            <div style={{
                width: '85%',
                display: 'inline-block',
                verticalAlign: 'top',
                textAlign: 'center',
                overflowY: 'auto',
                height: 'calc(100vh - 55px)'
            }}>
                <Outlet style={{height: '100%'}}/>
            </div>
        </div>
    )
}

export default Dashboard;