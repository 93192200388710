import React, {useEffect, useState} from 'react'
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import Header from "../Header/Header";
import {useNavigate, useParams} from "react-router-dom";
import {Col, Drawer, Empty, FloatButton, Image, notification, Row, Spin, Tag, theme, Typography} from "antd";
import axios from "../../axios_conf";
import {
    AimOutlined,
    FacebookOutlined,
    GlobalOutlined,
    InstagramOutlined,
    PhoneOutlined,
    TwitterOutlined
} from "@ant-design/icons";
import MenuItemCard from "../Dashboard/Menu/MenuItemCard";
import {
    ChatContainer,
    MainContainer,
    Message,
    MessageInput,
    MessageList, TypingIndicator
} from "@chatscope/chat-ui-kit-react";
import cropedai from '../../assets/ai-fork.png'
import {MapContainer, TileLayer, Marker} from "react-leaflet";
import Footer from "../Header/Footer";
import {Helmet} from "react-helmet";

const {useToken} = theme

const BusinessFrontPage = ({toggleTheme, user, setAuthStateChanged}) => {
    let { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [business, setBusiness] = useState({})
    const navigate = useNavigate();
    const {token} = useToken();
    const [visibleCategories, setVisibleCategories] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [conversation, setConversation] = useState([{
        message: "I'm TasteMate, your AI assistant! I am here to enhance your experience with our menu and provide personalized menu recommendations tailored to your dietary preferences and more. Feel free to ask me anything!",
        direction: "incoming"
    }])
    const [aiTyping, setAiTyping] = useState(false)

    useEffect(()=>{
        if(id==null){
            notification['error']({message:"Hmm, something went wrong"})
            return;
        }
        setLoading(true)
        axios.get("Businesses/"+id).then(res=>{
            setBusiness(res.data)
            setLoading(false)
        }).catch(er=>{
            if(er.response.status == 404){
                navigate('/404')
                return;
            }
            notification['error']({message: "Hmph, something went wrong. Please try again later"})
        })
    }, [id])

    useEffect(()=>{
        if(Object.keys(business).length === 0){
            return;
        }
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                const categoryName = entry.target.dataset.category;

                if (entry.isIntersecting) {
                    setVisibleCategories((prevVisibleCategories) => [
                        ...prevVisibleCategories,
                        categoryName,
                    ]);
                    const element = document.getElementById('tag-'+categoryName);
                    if (element) {
                        // 👇 Will scroll smoothly to the top of the next section
                        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                        console.log("Scroll")
                    }
                } else {
                    setVisibleCategories((prevVisibleCategories) =>
                        prevVisibleCategories.filter((category) => category !== categoryName)
                    );
                }
            });
        };

        const observer = new IntersectionObserver(callback, observerOptions);
        business.categories.forEach((category) => {
            const categoryElement = document.querySelector(`[data-category="${category.id}"]`);
            if (categoryElement) {
                observer.observe(categoryElement);
            }
        });

        return () => {
            observer.disconnect();
        };

    },[business])

    const getMapsUrl = () => {
        let base = 'https://www.google.com/maps/search/?api=1';
        base+='&query='+business.name.replaceAll(' ','+')+'+'
        base+=business.lat+','+business.lng;
        return base;
    }

    const sendMessageToAi = (data) => {
        setAiTyping(true)
        const fullMsgs = [...conversation, {"message": data, "direction": "outgoing"}]
        setConversation(fullMsgs)
        const outData = []
        for(let i=0;i<fullMsgs.length;i++){
            if(i==0) continue;
            outData.push({"role": fullMsgs[i].direction == 'outgoing' ? 'user' : 'assistant', "content": fullMsgs[i].message})
        }
        console.log(outData)
        axios.post("Businesses/"+id+"/ai-response", outData).then(res=>{
            fullMsgs.push({"message": res.data, "direction": 'incoming'})
            setConversation(fullMsgs)
            setAiTyping(false)
        }).catch(er=>{
            notification['error']({message: "Hmph, something went wrong. Please try again later"})
        })

    }

    return(
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{business.name} | αMenu</title>
                <meta name="description" content={business.name +" business information and menu | αMenu"} />
            </Helmet>
            <Header user={user} toggleTheme={toggleTheme} setAuthStateChanged={setAuthStateChanged}/>
            <div>
                {loading ? <div style={{width:'100%', height:'90vh'}}><Spin size={'large'} style={{marginLeft:'45%', marginTop:'100px'}}/></div> :
                    <div>
                        <div className={'fullcontainer'}>
                            <div id={'businessFrontImage'}>
                                <Image
                                    src={business.photoURL}
                                    id={'businessFrontInsideImage'}
                                    wrapperStyle={{overflow:'hidden',width:'100%'}}
                                    style={{width:'100%', height:'100%',margin: 'auto',objectFit:'cover'}}>
                                </Image>
                            </div>
                            <div id={'businessFrontInfo'} >
                                <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                                    <div id={'title'} style={{flexGrow:1}}>
                                        {business.name.length > 50 ? business.name.slice(0,70)+ '...': business.name}
                                        <div style={{color:'gray',fontSize:'10px'}}>{business.address}, {business.city}</div>
                                    </div>
                                    <div>
                                        { (business.lat!=41.9964 && business.lat!=0) || (business.lng!=21.43141&& business.lng!=0) ? <a className={'contact'} href={getMapsUrl()} target={'blank'} style={{marginLeft:0,paddingLeft:0}}><AimOutlined /></a> : ''}
                                        { business.contactURL ? <a className={'contact'} href={ business.contactURL }><GlobalOutlined /></a> : ''}
                                        { business.contactInstagram ? <a className={'contact'} href={ business.contactInstagram } style={{color:'#E1306C'}}><InstagramOutlined /></a> : ''}
                                        { business.contactFacebook ? <a className={'contact'} href={ business.contactFacebook } style={{color:'#4267B2'}}><FacebookOutlined /></a> : ''}
                                        { business.contactTwitter ? <a className={'contact'} href={ business.contactTwitter } style={{color:'#1DA1F2'}}><TwitterOutlined /></a> : ''}
                                        { business.contactNum ? <a className={'contact'} href={ 'tel:'+business.contactNum }>
                                            <PhoneOutlined />
                                        </a> : ''}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div id={'discroll'} style={{width:'100%', height:'50px', overflowX: 'auto', overflowY:'hidden', whiteSpace: 'nowrap', scrollbarWidth: 'none', backgroundColor: token.colorBgBase, borderColor: token.colorBorder}}>
                            {business.categories.sort((x,y) => x.order<y.order ? -1 : 1).map(el =>
                                <a href={"#cat"+el.id}>
                                <Tag
                                    key={el.id}
                                    id={'tag-'+el.id}
                                    color={visibleCategories.length>0 && el.id==visibleCategories.slice(-1) ? token.colorPrimary : ""}
                                    style={
                                        {
                                            borderRadius: '30px',
                                            cursor: 'pointer',
                                            padding: '5px 20px',
                                            margin: "10px"
                                        }
                                    }
                                >
                                    {el.name}
                                </Tag>
                                </a>
                            )}
                        </div>
                        <Row>
                            <Col xs={24} sm={24} lg={16} xl={16}>
                                {business.menu.length === 0 ?
                                    <Empty style={{marginTop:'50px'}} description={"This business hasn't added a menu yet"}/>
                                    : <></>
                                }
                                <div style={{padding:'20px', paddingTop: 0}}>
                                    {business.categories.sort((x,y) => x.order<y.order ? -1 : 1).map(cat => (
                                        <div key={cat.id} id={"cat"+cat.id} data-category={cat.id}>
                                            <Typography.Title level={3}>{cat.name}</Typography.Title>
                                            {business.menu.filter(x => x.category==cat.id).map(el => <MenuItemCard {...el} key={el.id} user={user} client={true} currency={business.defaultCurrency}/>)}
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} lg={8} xl={8}>
                                <div className={'stiki'} style={{padding:'20px', paddingTop: 0}}>
                                    {window.innerWidth<992 || !business.activeAiAssistant ? <></> :
                                        <div>
                                            <div onClick={()=>setDrawerVisible(true)} style={{cursor: 'pointer', margin: '20px 0 0 0', padding: '5px 5px 5px 5px', border: '1px solid '+token.colorBorder, backgroundColor: token.colorBgElevated, borderTopLeftRadius: token.borderRadius, borderTopRightRadius: token.borderRadius, borderBottom: 'none'}}>
                                                <img alt={'ai-logo'} src={cropedai} height={40} style={{verticalAlign:'middle', transform: 'rotate(30deg)'}}/>
                                                <Typography.Title level={4} style={{display:'inline-block', verticalAlign:'middle', margin:0}}>TasteMate</Typography.Title>
                                            </div>
                                            <div style={{ position:"relative", height: "50vh", border: '1px solid '+token.colorBorder, borderBottomRightRadius: token.borderRadius, borderBottomLeftRadius: token.borderRadius }}>
                                                <MainContainer style={{ border: 'none'}}>
                                                    <ChatContainer style={{borderColor: 'red'}}>
                                                        <MessageList style={{backgroundColor: token.colorBgBase, borderColor: 'red'}}
                                                                     typingIndicator={aiTyping ? <TypingIndicator style={{backgroundColor: token.colorBgElevated}} content="AI is thinking" /> : null}
                                                        >
                                                            {conversation.map(el =>
                                                                <Message key={el.message} model={{
                                                                    ...el,
                                                                    position: "single"
                                                                }} />
                                                            )}
                                                        </MessageList>
                                                        <MessageInput onSend={sendMessageToAi} style={{backgroundColor: token.colorBgElevated, borderColor: token.colorBorder}} attachButton={false} placeholder="Type message here" />
                                                    </ChatContainer>
                                                </MainContainer>
                                            </div>
                                        </div>
                                    }
                                    <div style={{margin:'20px 0', borderRadius: token.borderRadius}}>
                                        <MapContainer center={[business.lat, business.lng]} zoom={13} scrollWheelZoom={false} style={{height:'30vh', borderRadius: token.borderRadius}}>
                                            <Marker position={[business.lat, business.lng]}>
                                            </Marker>
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url={localStorage.getItem("theme")==='dark' ? "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" :"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                                            />
                                        </MapContainer>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
            <Drawer
                title={<div>
                        <img alt={'ai-logo'} src={cropedai} height={40} style={{verticalAlign:'middle', transform: 'rotate(30deg)'}}/>
                        <Typography.Title level={4} style={{display:'inline-block', verticalAlign:'middle', margin:0}}>TasteMate</Typography.Title>
                </div>}
                placement="bottom"
                onClose={()=>setDrawerVisible(false)}
                visible={drawerVisible}
                height="80vh" // Adjust the height as needed
            >
                <MainContainer style={{border: 'none'}}>
                    <ChatContainer>
                        <MessageList style={{backgroundColor: token.colorBgElevated}}
                                     typingIndicator={aiTyping ? <TypingIndicator style={{backgroundColor: token.colorBgElevated}} content="AI is thinking" /> : null}
                        >
                            {conversation.map(el =>
                                <Message key={el.message} model={{
                                    ...el,
                                    position: "single"
                                }} />
                            )}
                        </MessageList>
                        <MessageInput onSend={sendMessageToAi} style={{backgroundColor: token.colorBgElevated}} attachButton={false} placeholder="Type message here" />
                    </ChatContainer>
                </MainContainer>
            </Drawer>

            {window.innerWidth<992 && business.activeAiAssistant ?
                <FloatButton onClick={()=>setDrawerVisible(true)} icon={<img alt={'ai-logo'} src={cropedai} height={35} style={{marginLeft:'-10px', marginTop:'5px', transform: 'rotate(30deg)'}}/>}  type="default" style={{ right: 24, height:'50px', width:'50px' }} />
                : <></>}
            <Footer token={token}/>
        </div>
    )
}

export default BusinessFrontPage;