import {Typography, Breadcrumb, Button, Card, Row, Col, theme, Spin, Alert, Modal} from "antd";
import {EditOutlined, EyeOutlined, HomeOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import place from '../../assets/place.png'
import Meta from "antd/es/card/Meta";
import {Loading} from "../../App";
import React, {useEffect, useState} from "react";
import axios from "../../axios_conf";
import PaymentPlan from "../Auth/PaymentPlan";
const { useToken } = theme;

const MainPage = ({user, loading}) => {
    const navigate = useNavigate();
    const { token } = useToken();


    return (
        <div style={{textAlign:'start', padding:'20px'}}>
            <Breadcrumb
                items={[
                    {
                        title: <Link to="/dashboard"><HomeOutlined/></Link>,
                    },
                    {
                        title: "Dashboard"
                    },
                ]}
            />
            <Typography.Title level={2} style={{margin:'10px 0'}}>My business locations</Typography.Title>
            <br/>
            {loading ? <Loading/> :
            <div>
                {user.ownedBusinesses.length >= user.planSetting.businessesLimit ?
                    <Alert
                        message="You have used up all the available business slots in your plan"
                        type="warning"
                        action={
                            <Link to={"/contact"}>
                                Upgrade your plan!
                            </Link>
                        }
                        style={{marginBottom:'10px'}}
                        showIcon
                    />
                    :
                    user.ownedBusinesses.length === 0 && user.plan===0 ?
                    <Alert
                        message="You are currently on the Free plan"
                        type="info"
                        action={
                            <Link to={"/contact"}>
                                Upgrade your plan!
                            </Link>
                        }
                        style={{marginBottom:'10px'}}
                        showIcon
                    />
                    :
                    <></>}
                {user.ownedBusinesses.length !== 0 ?
                    <div style={{ width: "100%" }}>
                        <Row gutter={16} style={{ width: "100%" }} justify="start" align="middle">
                        {user.ownedBusinesses.map(business=>
                            <Col xs={24} sm={12} md={12} lg={8} xl={6} style={{marginBottom:'10px'}} key={business.id}>
                                <Card
                                    key={business.id}
                                    cover={business.photoURL!="" ? <img alt={'business image'} src={business.photoURL} height={100} style={{objectFit:'cover'}}/> : <img src={place} width={'100%'} alt={'place'} height={100} style={{objectFit:'contain'}}/>}
                                    actions={[
                                        <EyeOutlined onClick={()=>navigate("/business/"+business.slug)} style={{fontSize:'17px'}}/>,
                                        <EditOutlined onClick={()=>navigate("/dashboard/add-business?id="+business.slug)} style={{fontSize:'17px'}}/>,
                                    ]}
                                >
                                    <Meta title={business.name}/>
                                </Card>
                            </Col>
                        )}
                            {user.ownedBusinesses.length < user.planSetting.businessesLimit ?
                                <Col xs={24} sm={12} md={12} lg={8} xl={6} style={{marginBottom:'10px'}}>
                                    <Card
                                        cover={<img src={place} width={'100%'} alt={'place'} height={100} style={{objectFit:'contain'}}/>}
                                        style={{border: '3px solid '+token.colorPrimary}}
                                        hoverable
                                        onClick={()=>navigate("/dashboard/add-business")}
                                    >
                                        <Meta title={"Add a new business"} />
                                    </Card>
                                </Col>
                                :<></>}
                        </Row>
                    </div>
                    :
                    <div style={{ width: "100%" }}>
                        <div className="container" style={{paddingTop:0}}>
                            <div className="bigChildLonger" style={{textAlign:'start'}}>
                                <Typography.Title level={1}>Start by adding a business</Typography.Title>
                                <Typography.Paragraph>Ready to unleash your business to the internet? The first step is to add your business to our system! From there, you'll be able to manage everything from online orders to reservations and loyalty programs, all from one convenient dashboard. Let's get started!</Typography.Paragraph>
                                <Button type={'primary'} onClick={()=>navigate("/dashboard/add-business")}>Add a business</Button>

                            </div>
                            <div style={{ textAlign: "center" }} className="smallChildLonger">
                                <img src={place} width={'100%'} alt={'place'}/>
                            </div>
                        </div>
                    </div>
                }
                <br/>
            </div>
            }
        </div>
    )
}

export default MainPage;