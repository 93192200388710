import './App.css';
import {Button, ConfigProvider, Spin, theme} from "antd";
import Header from "./Components/Header/Header";
import React, {useCallback, useEffect, useState} from "react";
import FrontPage from "./Components/FrontPage/FrontPage";
import {
    createBrowserRouter, Navigate,
    RouterProvider,
} from "react-router-dom";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import useLoggedInUser from "./Hooks/useLoggedInUser";
import Verify from "./Components/Auth/Verify";
import ResetPassword from "./Components/Auth/ResetPassword";
import Dashboard from "./Components/Dashboard/Dashboard";
import MainPage from "./Components/Dashboard/MainPage";
import MenuPage from "./Components/Dashboard/Menu/MenuPage";
import AddBusiness from "./Components/Dashboard/AddBusiness";
import AddMenuItem from "./Components/Dashboard/Menu/AddMenuItem";
import BusinessFrontPage from "./Components/FrontPage/BusinessFrontPage";
import PaymentPlan from "./Components/Auth/PaymentPlan";
import Settings from "./Components/Dashboard/Settings";
import ContactPage from "./Components/FrontPage/ContactPage";
const {useToken} = theme


function App() {
    const { token } = useToken();
    const [th, setTh] = useState(true)

    useEffect(()=> {
        const item = localStorage.getItem("theme");
        if(item!=null){
            setTh(item=='light')
        }
    },[])

    const toggleTh = ()=>{
        localStorage.setItem("theme", th ? 'dark' : 'light')
        setTh(th=>!th)
    }
    return (
        <ConfigProvider theme={
            {
                token:
                    {
                        colorPrimary: '#FF6F61',
                        colorSuccess: '#6DBE45',
                        colorPrimaryBg: th?'rgb(255, 244, 240)':'#1E1E1E'
                    },
                algorithm: th ? theme.defaultAlgorithm : theme.darkAlgorithm
            }}>
            <Application toggleTheme={toggleTh}/>
        </ConfigProvider>
    );
}

export const Loading = () => <div className={'center'} style={{textAlign:'center'}}><Spin size={'large'}/></div>

const Application = ({toggleTheme}) => {
    const { token } = useToken();
    const [authStateChanged, setAuthStateChanged] = useState(-1)
    const [user, loading] = useLoggedInUser({authStateChanged});

    const updateAuthStateChanged = useCallback(() => {
        setAuthStateChanged(prevState => prevState + 1);
    }, []);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <FrontPage toggleTheme={toggleTheme} setAuthStateChanged={updateAuthStateChanged} user={user}/>,
        },
        {
            path: "/contact",
            element: <ContactPage toggleTheme={toggleTheme} setAuthStateChanged={updateAuthStateChanged} user={user}/>,
        },
        {
            path: "/business/:id",
            element: <BusinessFrontPage toggleTheme={toggleTheme} setAuthStateChanged={updateAuthStateChanged} user={user}/>,
        },
        {
            path: "/auth/plan",
            element: loading ? <Loading/> : user!=null ? <PaymentPlan/> : <Navigate to={'/auth/register'}/>,
        },
        {
            path: "/auth/login",
            element: loading ? <Loading/> : user==null ? <Login updateUser={updateAuthStateChanged}/> : <Navigate to={'/dashboard'}/>,
        },
        {
            path: "/auth/register",
            element: loading ? <Loading/> : user==null ? <Register updateUser={updateAuthStateChanged}/> : <Navigate to={'/dashboard'}/>,
        },
        {
            path: "/auth/verify",
            element: <Verify/>,
        },
        {
            path: "/auth/reset",
            element: loading ? <Loading/> :user==null ? <ResetPassword/> : <Navigate to={'/dashboard'}/>,
        },
        {
            path: "/dashboard",
            element: user!=null ? <Dashboard user={user} setAuthStateChanged={updateAuthStateChanged} toggleTheme={toggleTheme}/> : <Navigate to={'/auth/login'}/>,
            children: [
                {
                    path: "/dashboard",
                    element: <MainPage user={user} loading={loading}/>
                },
                {
                    path: "/dashboard/add-business",
                    element: <AddBusiness setAuthStateChanged={updateAuthStateChanged} user={user} loading={loading}/>
                },
                {
                    path: "/dashboard/menu",
                    element: <MenuPage user={user} loading={loading}/>
                },
                {
                    path: "/dashboard/add-menu",
                    element: <AddMenuItem user={user} loading={loading}/>
                },
                {
                    path: "/dashboard/settings",
                    element: <Settings user={user} loading={loading}/>
                },

            ]
        },
    ]);
    return(
    <div style={{backgroundColor: token.colorPrimaryBg}}>
        <RouterProvider router={router} />
    </div>
    )
}

export default App;
