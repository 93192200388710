import React, {useEffect} from 'react'

const PaymentPlan = ({}) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const element = React.createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1NvzGKJERPdlKa7kT3W4Zwor",
        "publishable-key": "pk_test_51NtSF5JERPdlKa7kTTuSAk9gaEPVX2DyJQdLbBZYri34Jh4jDQ9pkLVVMJi8IU6FjfFRaQfEP39P2TnELhq2gOAq003saDvzbm"})
    return(
        <div>
            {element}
        </div>
    )
}

export default PaymentPlan;