import { useEffect, useState, useMemo } from "react";
import axios from '../axios_conf'

const useLoggedInUser = ({ authStateChanged }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true)

    const fetchUser = useMemo(() => () => {
        setLoading(true)
        axios.get("/Users/current")
            .then(response => {
                if (response.data && response.data !== "") {
                    setUser(response.data);
                } else {
                    setUser(null);
                }
                setLoading(false)
            })
            .catch(error => {
                setUser(null);
                setLoading(false)
            });
    }, []);

    useEffect(() => {
        fetchUser();
    }, [authStateChanged, fetchUser]);

    return [user, loading];
};

export default useLoggedInUser;
