import React, {useEffect, useRef} from "react";
import {Button, Card, Carousel, Col, QRCode, Row, Space, theme, Typography} from "antd";
import businessGraphic from '../../assets/businessGraphic.png'
import ordersGraphic from '../../assets/orders.png'
import reservationsGraphic from '../../assets/reservations.png'
import chartGraphic from '../../assets/chartGraphic.png'
import employeeGraphic from '../../assets/employees.png'
import eventGraphic from '../../assets/events.png'
import loyaltyGraphic from '../../assets/loyalty.png'
import aifork from '../../assets/ai-fork.png'
import qrcode1 from '../../assets/qrcode-1.png'
import qrcode2 from '../../assets/qrcode-2.png'
import qrcode3 from '../../assets/qrcode-3.png'
import qrcode4 from '../../assets/qrcode-4.png'
import frontpage from '../../assets/frontpage.png'
import '../../App.css'
import Faq from 'react-faq-component';
import Header from "../Header/Header";
import Footer from "../Header/Footer";
import {DoubleRightOutlined} from "@ant-design/icons";
import {Helmet} from "react-helmet";

const { useToken } = theme;

const FrontPage = ({toggleTheme, user, setAuthStateChanged}) => {
    const { token } = useToken();
    const element = useRef(null);
    const prefix = "your ";
    const _CONTENT = [
        prefix + "restaurant",
        prefix + "bar",
        prefix + "café",
        prefix + "business",
    ];
    let _PART = 0;
    let _PART_INDEX = prefix.length;
    let _INTERVAL_VAL;
    let _ELEMENT;

    const Type = () => {
        let text = _CONTENT[_PART].substring(0, _PART_INDEX + 1);
        _ELEMENT.textContent = text;
        _PART_INDEX++;

        if (text === _CONTENT[_PART]) {
            clearInterval(_INTERVAL_VAL);
            setTimeout(function () {
                _INTERVAL_VAL = setInterval(Delete, 50);
            }, 1000);
        }
    };

    const Delete = () => {
        let text = _CONTENT[_PART].substring(0, _PART_INDEX - 1);
        _ELEMENT.textContent = text;
        _PART_INDEX--;

        if (text === prefix) {
            clearInterval(_INTERVAL_VAL);

            if (_PART === _CONTENT.length - 1) _PART = 0;
            else _PART++;

            _PART_INDEX = prefix.length;

            setTimeout(function () {
                _INTERVAL_VAL = setInterval(Type, 100);
            }, 200);
        }
    };

    useEffect(() => {
        _ELEMENT = element.current;
        _INTERVAL_VAL = setInterval(Type, 100);

        return () => {
            clearInterval(_INTERVAL_VAL);
        };
    }, []);
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>αMenu - Smart business tools</title>
                <meta name="description" content="Transform your restaurant/cafe/bar with AI" />
            </Helmet>
            <Header user={user} toggleTheme={toggleTheme} setAuthStateChanged={setAuthStateChanged}/>
            <div>
                <div className="container" style={{borderTop: '1px solid black'}}>
                    <div className={'bigChildLonger'}>
                        <Typography.Title level={1}>
                            <div id={'text'} style={{textShadow: "1px 1px 1px rgba(0, 0, 0, 0.15))"}}>
                                <span><b
                                    style={{marginRight: "5px", textDecoration: 'underline'}}>Smart</b> tools for</span>
                                <br/>
                                <div ref={element}/>
                            </div>
                        </Typography.Title>
                        <Typography.Title level={3} id={'subtext'}>Empower Your Business with All-In-One
                            Management Tools! <br/> Get Started for Free, No Credit Card Required! </Typography.Title>
                        <br/>
                        <Space>
                            <Button
                                type="primary"
                                href="/#pricing"
                                size="large"
                            >
                                See Pricing
                            </Button>
                            <Button type={'link'} href={'/contact'} style={{textDecoration: 'underline'}}>Contact
                                Us</Button>
                        </Space>
                    </div>
                    <div style={{textAlign: "center", marginLeft: 0}} className="smallChildLonger">
                        <img src={frontpage} style={{width: "80%", filter: "drop-shadow(5px 5px 5px #222)"}}
                             alt={'graphic'}/>
                    </div>
                </div>
                <br/>
                <br/>
                <div style={{width: 'calc(100%-20px)', backgroundColor: token.colorBgSpotlight, padding: '10px 20px'}}>
                    <Typography.Title level={2} style={{margin: 0, color: 'white'}}>Features</Typography.Title>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <div style={{display: 'inline-block', filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}>
                            <img src={businessGraphic}
                                 style={{width: "200px", filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}
                                 alt={'frontpage'}/>
                        </div>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>Boost Your Online Presence</Typography.Title>
                        <Typography.Text>
                            Showcase your menu and business information to customers through a convenient QR code,
                            making it easily accessible and increasing your online visibility.
                        </Typography.Text>
                    </div>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <div style={{display: 'inline-block', filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))", width:'100%'}}>
                            <Carousel autoplay autoplaySpeed={2500} style={{width:'180px', height:'200px', borderRadius: token.borderRadius, margin:'auto'}}>
                                <div>
                                    <img src={qrcode1}
                                         style={{width: "180px", borderRadius: token.borderRadius}}
                                         alt={'qr-1'}/>
                                </div>
                                <div>
                                    <img src={qrcode2}
                                         style={{width: "180px", borderRadius: token.borderRadius}}
                                         alt={'qr-1'}/>
                                </div>
                                <div>
                                    <img src={qrcode3}
                                         style={{width: "180px", borderRadius: token.borderRadius}}
                                         alt={'qr-1'}/>
                                </div>
                                <div>
                                    <img src={qrcode4}
                                         style={{width: "180px", borderRadius: token.borderRadius}}
                                         alt={'qr-1'}/>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>Customizable QR-Codes!</Typography.Title>
                        <Typography.Text>
                            Tailor your QR code to match your business's unique aesthetic by customizing its design to suit your preferences.
                        </Typography.Text>
                    </div>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <div style={{display: 'inline-block', filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}>
                            <img src={aifork}
                                 style={{width: "200px", filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))", transform: 'rotate(30deg)'}}
                                 alt={'ai-fork'}/>
                        </div>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>Elevate Customer Engagement with Intelligent AI</Typography.Title>
                        <Typography.Text>
                            Empower your customers to receive personalized menu recommendations based on their dietary preferences and easily access crucial business information, creating a seamless and interactive experience that enhances your overall business presence.
                        </Typography.Text>
                    </div>
                </div>
                <div style={{borderBottom: '1px solid ' + token.colorBorder, padding: '10px', textAlign: 'center'}}>
                    <Typography.Text color={"gray"}><DoubleRightOutlined rotate={90}/> Coming soon <DoubleRightOutlined rotate={90}/></Typography.Text>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <img src={ordersGraphic}
                             style={{width: "200px", filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}
                             alt={'graphic'}/>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>Streamline Your Orders with Smart Order
                            Management</Typography.Title>
                        <Typography.Text>
                            Simplify your order process by allowing customers to place orders directly from their mobile
                            phone using a QR code on their table. This efficient system reduces staff workload and
                            enhances customer convenience, leading to a smoother and more efficient order management
                            experience.
                        </Typography.Text>
                    </div>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <img src={reservationsGraphic}
                             style={{width: "200px", filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}
                             alt={'graphic'}/>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>Effortless Reservation
                            Management</Typography.Title>
                        <Typography.Text>
                            Streamline your reservation process with a centralized reservation management system that
                            allows you to effortlessly handle all your bookings in one place. Keep track of
                            reservations, make edits as needed, and ensure smooth operations for your business,
                            providing a seamless experience for your customers.
                        </Typography.Text>
                    </div>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <img src={loyaltyGraphic}
                             style={{width: "200px", filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}
                             alt={'graphic'}/>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>Reward Your Customers with a Loyalty
                            Program</Typography.Title>
                        <Typography.Text>
                            Implement a customized loyalty program to show appreciation to your customers and encourage
                            repeat business. Offer exclusive discounts, special offers, and rewards to incentivize
                            customer loyalty and foster long-term relationships, boosting customer retention and
                            satisfaction.
                        </Typography.Text>
                    </div>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <img src={eventGraphic}
                             style={{width: "200px", filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}
                             alt={'graphic'}/>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>Efficiently Plan and Organize
                            Events</Typography.Title>
                        <Typography.Text>
                            Efficiently plan and organize events in one centralized platform, reaching out to new
                            potential customers. Easily create and promote events, manage RSVPs, and streamline event
                            planning, all in one simple place, to boost attendance and drive business growth.
                        </Typography.Text>
                    </div>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <img src={employeeGraphic}
                             style={{width: "200px", filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}
                             alt={'graphic'}/>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>Streamline Employee
                            Management</Typography.Title>
                        <Typography.Text>
                            Effortlessly manage your employees, their shifts, and more with a detailed overview of their
                            performance. Simplify employee management tasks, optimize scheduling, and enhance
                            productivity in one comprehensive platform, providing you with seamless control over your
                            workforce.
                        </Typography.Text>
                    </div>
                </div>
                <div className="container" style={{borderBottom: '1px solid ' + token.colorBorder}}>
                    <div style={{textAlign: "center"}} className="smallChild">
                        <img src={chartGraphic}
                             style={{width: "200px", filter: "drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15))"}}
                             alt={'graphic'}/>
                    </div>
                    <div className="bigChild">
                        <Typography.Title level={2} style={{marginTop: 0}}>In-Depth Business Insights</Typography.Title>
                        <Typography.Text>
                            Gain valuable insights into your business performance with detailed statistics and
                            analytics. Track key metrics, evaluate performance, and identify areas for improvement to
                            drive business growth. Make data-driven decisions to optimize your business strategies and
                            stay ahead of the competition with comprehensive and actionable insights.
                        </Typography.Text>
                    </div>
                </div>
                <div id={'pricing'} style={{width: 'calc(100%-20px)', backgroundColor: token.colorBgSpotlight, padding: '10px 20px'}}>
                    <Typography.Title level={2} style={{margin: 0, color: 'white'}}>Pricing</Typography.Title>
                </div>
                <div style={{padding: '20px'}}>
                    <Row gutter={16} justify="space-evenly" align="middle">
                        <Col xs={24} md={12} xl={6} style={{marginBottom: '10px'}}>
                            <Card title="Small Business Plan" bordered={false}>
                                <ul>
                                    <li>Web presence for your business</li>
                                    <li><b>1</b> Location</li>
                                    <li>Customizable QR-Code for your business</li>
                                    <li><b>10</b> Menu Items</li>
                                    <li style={{color: 'gray'}}>No AI Assistant</li>
                                </ul>
                                <div style={{textAlign: 'center'}}>
                                    <Typography.Title level={2} style={{margin: 0}}>Always Free</Typography.Title>
                                    <br/>
                                    <Button
                                        type="primary"
                                        href="/auth/register"
                                        size="large"
                                    >
                                        Get Started
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} xl={6} style={{marginBottom: '10px'}}>
                            <Card title="Growth Plan" bordered={false}>
                                <ul>
                                    <li>Web presence for your business</li>
                                    <li><b>1</b> Location</li>
                                    <li>Customizable QR-Code for your business</li>
                                    <li><b>30</b> Menu Items</li>
                                    <li><b>1,000</b> AI Assistant messages monthly</li>
                                </ul>
                                <div style={{textAlign: 'center'}}>
                                    <Typography.Title level={2} style={{margin: 0}}>30$/mo</Typography.Title>
                                    <br/>
                                    <Button
                                        type="primary"
                                        href="/auth/register"
                                        size="large"
                                    >
                                        Get Started
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} xl={6} style={{marginBottom: '10px'}}>
                            <Card title="Pro Plan" bordered={false}>
                                <ul>
                                    <li>Web presence for your businesses</li>
                                    <li><b>3</b> Locations</li>
                                    <li>Customizable QR-Code for your businesses</li>
                                    <li><b>Unlimited</b> Menu Items</li>
                                    <li><b>10,000</b> AI Assistant messages monthly</li>
                                </ul>
                                <div style={{textAlign: 'center'}}>
                                    <Typography.Title level={2} style={{margin: 0}}>150$/mo</Typography.Title>
                                    <br/>
                                    <Button
                                        type="primary"
                                        href="/auth/register"
                                        size="large"
                                    >
                                        Get Started
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} xl={6} style={{marginBottom: '10px'}}>
                            <Card title="Unlimited Plan" bordered={false}>
                                <ul>
                                    <li>Web presence for your businesses</li>
                                    <li><b>Unlimited</b> Locations</li>
                                    <li>Customizable QR-Code for your businesses</li>
                                    <li><b>Unlimited</b> Menu Items</li>
                                    <li><b>Unlimited</b> AI Assistant messages monthly</li>
                                </ul>
                                <div style={{textAlign: 'center'}}>
                                    <Typography.Title level={2} style={{margin: 0}}>Contact us!</Typography.Title>
                                    <br/>
                                    <Button
                                        type="primary"
                                        href="/auth/register"
                                        size="large"
                                    >
                                        Get Started
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <div style={{textAlign: 'center'}}>
                        <Typography.Text>You can always change your plan at any time</Typography.Text>
                    </div>
                    <div>
                        <Faq
                            data={{
                                title: 'FAQ',
                                rows: [
                                    {
                                        title: 'How can clients access my menu?',
                                        content: 'For each of your businesses, a website is generated with all the contact information and menu. Customers can access this website through the QR-Code.'
                                    }, {
                                        title: 'What is TasteMate AI?',
                                        content: 'TasteMate is our AI assistant developed to assist your customers by addressing their queries and delivering personalized menu recommendations based on their dietary preferences. TasteMate understands your business!'
                                    }, {
                                        title: 'Do I need to download anything?',
                                        content: 'No, the whole system is cloud-based and accessible via a web browser'
                                    }, {
                                        title: 'Can I upgrade/downgrade my plan?',
                                        content: 'Yes you can modify your plan according to your needs at any time'
                                    }
                                ]
                            }}
                            styles={{
                                titleTextColor: token.colorText,
                                rowContentColor: 'gray',
                                rowTitleColor: token.colorText,
                                rowTitleTextSize: '15px',
                                rowContentTextSize: '13px',
                                bgColor: token.colorPrimaryBg
                            }}
                        />
                    </div>
                </div>
                <Footer token={token}/>
            </div>
        </div>

    );
}

export default FrontPage;