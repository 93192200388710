import React, {useEffect, useRef, useState} from 'react'
import {
    Breadcrumb, Button,
    Card,
    Divider,
    Form,
    Input,
    InputNumber,
    notification,
    Select,
    Spin,
    Typography,
    Upload,
    Checkbox
} from "antd";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {HomeOutlined, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import axios from "../../../axios_conf";

const AddMenuItem = ({user, loading}) => {

    const [loadingData, setLoadingData] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [form] = Form.useForm();
    const [categories, setCategories] = useState({items:[],name:''})
    const [fileList, setFileList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(()=>{
        axios.get("/Menu/categories")
            .then(response => {
                setCategories({...categories, items: response.data})
                setLoadingData(false)
            })
            .catch(error => {
                notification['error']({
                    message: 'An error occurred. Please try again later or contact support if the error persists!'
                });
                setLoadingData(false)
            });
    },[])

    useEffect(()=>{
        setLoadingData(true)
        const param = searchParams.get('id')
        if(param != null){
            axios.get("/Menu/"+param).then(res=>{
                form.setFieldsValue(res.data);
                if(res.data.photoUrl.length>0) {
                    setFileList([
                        {
                            uid: '1',
                            name: 'image.png',
                            status: 'done',
                            url: res.data.photoUrl,
                        },
                    ])
                }
                setLoadingData(false)
            })
        }else{
            setLoadingData(false)
        }
    },[searchParams])

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const finishForm = async (data) => {
        console.log(categories)
        const cat = categories.items.filter(x => x.id==data.category)[0]
        data.category = cat
        let photoUrl = ""
        try{
            photoUrl = await toBase64(data.photoUrl.file)
        }
        catch{
            if(fileList.length>0){
                photoUrl = fileList[0].url
            }
        }
        const fullData = {
            ...data,
            photoUrl: photoUrl
        }
        setLoadingUpload(true)
        console.log(fullData)
        const param = searchParams.get('id')
        if(param != null) {
            axios.put("/Menu/"+param, fullData).then(res=>{
                setLoadingUpload(false)
                navigate("/dashboard/Menu")
            }).catch(()=>{
                notification['error']({
                    message: 'An error occurred',
                    description:
                        'Please try again later!',
                });
                setLoadingUpload(false)
            })
            return;
        }
        axios.post('/Menu/', fullData).then(res=>{
            setLoadingUpload(false)
            navigate('/dashboard/menu')
        }).catch(er => {
            notification['error']({
                message: 'An error occurred. Please try again later or contact support if the error persists!'
            });
            setLoadingUpload(false)
        })
    }

    const addItem = () => {
        const { items, name } = categories;
        if(!name){
            notification['warning']({
                message: 'Enter new category name'
            });
            return;
        }

        setCategories({
            items: [...items, {"name": name, "order": 0, "id": 0}],
            name: ''
        });
    };

    const onNameChange = event => {
        setCategories({...categories, name: event.target.value});
    };

    return(
        <div>
            <div style={{textAlign:'start', padding:'20px'}}>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/dashboard"><HomeOutlined/></Link>,
                        },
                        {
                            title: <Link to="/dashboard/menu">Menu</Link>
                        },
                        {
                            title:  searchParams.get('id')==null ? "Add menu-item" : "Edit menu-item"
                        },
                    ]}
                />
                <Typography.Title level={2} style={{margin:'10px 0'}}>{searchParams.get('id')==null ? 'New menu-item' : 'Edit menu-item'}</Typography.Title>
                <br/>
                {loadingData ? <Spin size={'large'}/> :
                    <Card>
                        <Form form={form} layout={'vertical'} onFinish={finishForm} initialValues={{businessIds: user.ownedBusinesses.map(el => el.id)}}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Title is required',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <TextArea/>
                            </Form.Item>
                            <Form.Item
                                label="Original Price"
                                name="originalPrice"
                                help={'Enter the price in the currency of the business'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Price is required',
                                    },
                                ]}
                            >
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item
                                label="Discounted Price"
                                name="currentPrice"
                                help={'Leave blank to show only the original price. If a lower price is entered, this item will be highlighted on your page.'}
                            >
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item
                                name="photoUrl"
                                label="Banner"
                            >
                                <Upload name="photoUrl" maxCount={1} listType="picture-card" accept={'.png,.jpg,.jpeg'} beforeUpload={()=>false} onRemove={()=>true} fileList={fileList} onChange={(newFileList)=>setFileList(newFileList.fileList)}>
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                label="Tags"
                                name="tags"
                            >
                                <Select mode="tags" tokenSeparators={[',']} placeholder={'Enter tags associated with this food'}>
                                    <Select.Option key={'Vegeterian'}>Vegeterian</Select.Option>
                                    <Select.Option key={'Vegan'}>Vegan</Select.Option>
                                    <Select.Option key={'Gluten Free'}>Gluten free</Select.Option>
                                    <Select.Option key={'Hot'}>Hot</Select.Option>
                                    <Select.Option key={'Kosher'}>Kosher</Select.Option>
                                    <Select.Option key={'Halal'}>Halal</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Allergens"
                                name="allergens"
                            >
                                <Select mode="tags" tokenSeparators={[',']} placeholder={'Enter potential allergens found in this item'}>
                                    <Select.Option key={'milk'}>Milk</Select.Option>
                                    <Select.Option key={'eggs'}>Eggs</Select.Option>
                                    <Select.Option key={'peanuts'}>Peanuts</Select.Option>
                                    <Select.Option key={'tree nuts'}>Tree nuts (e.g., almonds, walnuts, cashews)</Select.Option>
                                    <Select.Option key={'wheat'}>Wheat</Select.Option>
                                    <Select.Option key={'soybeans'}>Soybeans</Select.Option>
                                    <Select.Option key={'fish'}>Fish</Select.Option>
                                    <Select.Option key={'shellfish'}>Shellfish</Select.Option>
                                    <Select.Option key={'sesame seeds'}>Sesame seeds</Select.Option>
                                    <Select.Option key={'mustard'}>Mustard</Select.Option>
                                    <Select.Option key={'sulphites'}>Sulphites</Select.Option>
                                    <Select.Option key={'gluten'}>Gluten</Select.Option>
                                    <Select.Option key={'lupin'}>Lupin</Select.Option>
                                    <Select.Option key={'celery'}>Celery</Select.Option>
                                    <Select.Option key={'mollusks'}>Mollusks (e.g., clams, mussels, oysters)</Select.Option>
                                    <Select.Option key={'crustaceans'}>Crustaceans (e.g., crabs, lobsters, shrimp)</Select.Option>
                                    <Select.Option key={'kiwi'}>Kiwi</Select.Option>
                                    <Select.Option key={'pinenuts'}>Pine nuts</Select.Option>
                                    <Select.Option key={'avocado'}>Avocado</Select.Option>
                                    <Select.Option key={'stone fruits'}>Stone fruits (e.g., cherries, peaches, plums)</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[{ required: true, message: 'Please select a category' }]}
                            >
                                <Select
                                    placeholder="Choose an existing category or create a new one"
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{margin: '4px 0'}}/>
                                            <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                                <Input style={{flex: 'auto'}} value={categories.name} onChange={onNameChange}/>
                                                <a
                                                    style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer'}}
                                                    onClick={addItem}
                                                >
                                                    <PlusOutlined/> New Category
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {categories.items.map(item => (
                                        <Select.Option key={item.id + " " + item.name} value={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Add to business"
                                name="businessIds"
                                help={'Select the business locations that this menu item will be available to'}
                                rules={[{ required: true, message: 'Please select at least one business' }]}
                            >
                                <Checkbox.Group options={user.ownedBusinesses.map(el => {return{label: el.name, value: el.id}})}/>
                            </Form.Item>
                            <Form.Item style={{textAlign:'center'}}>
                                <Button type="primary" htmlType="submit" loading={loadingUpload}>
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                        }
            </div>
        </div>
    )
}

export default AddMenuItem;