import {Button, Card, Checkbox, Form, Input, notification, Space, Typography} from "antd";
import '../../App.css'
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import axios from "../../axios_conf";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const Login = ({updateUser}) => {
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const onFinish = (values) => {
        setLoading(true)
        axios.post("/Users/login", {email:values.email, password: values.password}).then(res=>{
            localStorage.setItem("JWT", "Bearer "+res.data);
            updateUser(Math.random())
            setTimeout(()=>{
                setLoading(false)
                navigate("/dashboard")
            },1000)

        }).catch(el=>{
            api['error']({
                message: 'Incorrect credentials',
                description:
                    'Wrong email or password. Please try again!',
            });
            setLoading(false)
        })
    };
    return(
        <div style={{width:'100vw',height:'100vh'}}>
            {contextHolder}
                <Card style={{borderRadius:'20px'}} className='center'>
                    <div style={{textAlign:'center'}}>
                        <Typography.Title level={1}>Login</Typography.Title>
                    </div>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your Email!' }, {type: 'email', message: 'Please input a valid email'}]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>

                                <a className="login-form-forgot" href="/auth/reset" style={{float:'right'}}>
                                    Forgot password
                                </a>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{width:'100%'}} className="login-form-button" loading={loading}>
                                Log in
                            </Button>
                            Or <a href="/auth/register">register now!</a>
                        </Form.Item>
                    </Form>
                </Card>
        </div>
    )
}

export default Login;