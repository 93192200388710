import {Typography} from "antd";
import React from "react";

function Footer(props) {
    return <div style={{
        width: "calc(100%-20px)",
        backgroundColor: props.token.colorBgSpotlight,
        padding: "10px 20px",
        textAlign: "center"
    }}>
        <Typography.Text style={{margin: 0, color: "white"}}>αMenu - All rights reserved | Made with ❤️ in 🇲🇰</Typography.Text>
    </div>;
}

export default Footer