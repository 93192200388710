import React, {useEffect, useState} from 'react'
import {Alert, Breadcrumb, Button, Card, Col, Drawer, Empty, Row, Space, theme, Typography} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {EditOutlined, EyeOutlined, HomeOutlined, QrcodeOutlined} from "@ant-design/icons";
import {Loading} from "../../../App";
import place from "../../../assets/place.png";
import Meta from "antd/es/card/Meta";
import axios from "../../../axios_conf";
import MenuItemCard from "./MenuItemCard";
import CategoryList from "./CategoryList";
import QrCodeGenerator from "./QrCodeGenerator";

const {useToken} = theme;

const MenuPage = ({user, loading}) => {

    const navigate = useNavigate();
    const [menu, setMenu] = useState([])
    const [categories, setCategories] = useState([])
    const [categoriesReordered, setCategoriesReordered] = useState([])
    const [menuLoading, setMenuLoading] = useState(true)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawerQR, setOpenDrawerQR] = useState(false);
    const { token } = useToken();

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const onClose = () => {
        setOpenDrawer(false);
        setCategoriesReordered(categories)
    };

    const orderChanged = (src, dest) => {
        console.log(src, dest)
        const updatedCategories = [...categoriesReordered];

        const [category] = updatedCategories.splice(src, 1);
        updatedCategories.splice(dest, 0, category);

        setCategoriesReordered(updatedCategories);
    }

    const saveOrder = () => {
        console.log('save')
        const updatedCategories = [...categoriesReordered];
        for(let i=0; i<categoriesReordered.length; i++){
            updatedCategories[i].order = i;
        }
        setMenuLoading(true)
        setOpenDrawer(false);
        console.log(updatedCategories)
        axios.put("/Menu/categories", updatedCategories).then(res => {
            setCategories(updatedCategories)
            setMenuLoading(false)
        })
    }
    const deletedItem = () => {
        getData()
    }

    const getData = () => {
        setMenuLoading(true)
        axios.get('/Menu/categories').then(cats => {
            setCategories(cats.data.sort((a, b) => (a.order > b.order) ? 1 : -1))
            setCategoriesReordered(cats.data.sort((a, b) => (a.order > b.order) ? 1 : -1))
            console.log(cats.data)
            axios.get("/Menu/all").then(res=>{
                setMenu(res.data)
                console.log(res.data)
                console.log(user)
                setMenuLoading(false)
            })
        })
    }

    useEffect(()=>{
        console.log(user)
        getData()
    }, [user])
    return(
        <div style={{textAlign:'start', padding:'20px', paddingRight:'40px'}}>
            <Drawer
                title="Reorder Categories"
                width={'80%'}
                onClose={onClose}
                open={openDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={saveOrder} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <CategoryList categories={categoriesReordered} onCategoryOrderChange={orderChanged}/>
            </Drawer>
            <Drawer
                title="Generate QRCode"
                width={'90%'}
                onClose={()=>setOpenDrawerQR(false)}
                open={openDrawerQR}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <QrCodeGenerator user={user}/>
            </Drawer>
            <Breadcrumb
                items={[
                    {
                        title: <Link to="/dashboard"><HomeOutlined/></Link>,
                    },
                    {
                        title: "Menu"
                    },
                ]}
            />
            <Row justify="space-between" align="middle">
                <Row><Typography.Title level={2} style={{margin:'10px 0'}}>Menu items</Typography.Title></Row>
                <Row gutter={8}>
                    <Col>
                    <Button type="primary" ghost onClick={()=>showDrawer()} style={{marginBottom:'5px'}}>
                        Reorder categories
                    </Button>
                    </Col>
                    <Col>
                    <Button icon={<QrcodeOutlined />} onClick={()=>setOpenDrawerQR(true)}  style={{backgroundColor: token.colorText, color: token.colorBgBase}}>
                        Generate QRCode
                    </Button>
                    </Col>
                    <Col>
                    <Button type="primary" onClick={()=>navigate('/dashboard/add-menu')} disabled={menu.length >= user.planSetting.menuItemsLimit} >
                        Add new
                    </Button>
                    </Col>
                </Row>
            </Row>
            <br/>
            {menu.length >= user.planSetting.menuItemsLimit ?
                <Alert
                    message="You have used up all the available menu item slots in your plan"
                    type="warning"
                    action={
                        <Link to={"/contact"}>
                            Upgrade your plan!
                        </Link>
                    }
                    showIcon
                />
                :
                <></>
            }
            {menu.length === 0 ? <Empty style={{marginTop:'50px'}} description={"Added menu items will show up here!"}/> : <></>}
            {(loading || menuLoading) ? <Loading/> :
                <div>
                    {categories.map(cat => (
                        <div key={cat.id}>
                            <Typography.Title level={3}>{cat.name}</Typography.Title>
                            {menu.filter(x => x.category==cat.id).map(el => <MenuItemCard {...el} key={el.id} user={user} deleted={deletedItem} currency={"USD"}/>)}
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default MenuPage