import {Button, Col, Row, Space, theme, Typography} from "antd";
import {BgColorsOutlined} from '@ant-design/icons'
import {useNavigate} from "react-router-dom";

const { useToken } = theme;

const Header = ({user, toggleTheme, setAuthStateChanged}) => {
    const { token } = useToken();
    const navigate = useNavigate()

    const logout = () => {
        localStorage.setItem("JWT", "null");
        setAuthStateChanged();
    }
    return(
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{borderBottom:'1px solid '+token.colorBorder,  padding:'10px', width:'100%', height:'55px', zIndex:99}}>
            <Col style={{textAlign:'start'}}>
                <Typography.Title level={3} style={{margin: 0, marginLeft: 10, cursor: 'pointer'}} onClick={()=>navigate("/")}>αmenu</Typography.Title>
            </Col>
            {user == null ?
                <Col flex={'auto'} style={{textAlign:'right'}}>
                    <Space>
                        <BgColorsOutlined style={{fontSize: '20px', color:token.colorText}} onClick={toggleTheme}/>
                        <Button type={'text'} onClick={()=>navigate("/auth/login")} >Log in</Button>
                        <Button type={'primary'} onClick={()=>navigate("/auth/register")}>Get Started</Button>
                    </Space>
                </Col>
                :
                <Col flex={'auto'} style={{textAlign:'right'}}>
                    <Space>
                        <BgColorsOutlined style={{fontSize: '20px', color:token.colorText}} onClick={toggleTheme}/>
                        <Button type={'text'} onClick={()=>logout()}>Log out</Button>
                        <Button type={'primary'} onClick={()=>navigate("/dashboard")}>Dashboard</Button>
                    </Space>
                </Col>
            }
        </Row>
    )
}

export default Header;