import React, { useState, useEffect } from 'react';
import axios from '../../../axios_conf';
import {Form, Input, Select, ColorPicker, notification, Spin, Button} from 'antd';

const { Option } = Select;

const QrCodeGenerator = ({user}) => {
    const [selectedBusiness, setSelectedBusiness] = useState(user.ownedBusinesses[0].slug)
    const [drawer, setDrawer] = useState('square');
    const [colorMask, setColorMask] = useState('radial');
    const [backColor, setBackColor] = useState("rgb(255, 255, 255)");
    const [primColor, setPrimColor] = useState("rgb(0, 0, 0)");
    const [secColor, setSecColor] = useState("rgb(0, 0, 0)");
    const [qrCodeImage, setQrCodeImage] = useState('');
    const [loadingCode, setLoadingCode] = useState(true)

    useEffect(()=>{
        setSelectedBusiness(user.ownedBusinesses[0].slug)
    },[user])

    useEffect( () => {
        generateQrCode();
    }, [drawer, colorMask, backColor, primColor, secColor, user, selectedBusiness]);

    const generateQrCode = async () => {
        setLoadingCode(true)
        try {
            const bck = [
                parseInt(backColor.split('(')[1].split(',')[0]),
                parseInt(backColor.split(',')[1].split(',')[0]),
                parseInt(backColor.split(',')[2].split(')')[0])
            ]
            const prim = [
                parseInt(primColor.split('(')[1].split(',')[0]),
                parseInt(primColor.split(',')[1].split(',')[0]),
                parseInt(primColor.split(',')[2].split(')')[0])
            ]
            const sec = [
                parseInt(secColor.split('(')[1].split(',')[0]),
                parseInt(secColor.split(',')[1].split(',')[0]),
                parseInt(secColor.split(',')[2].split(')')[0])
            ]
            const response = await axios.post('/Menu/qrcode', {
                drawer,
                url: "https://alfamenu.bar/business/"+selectedBusiness,
                color_mask: colorMask,
                back_color: bck,
                prim: prim,
                sec: sec,
            },{
                responseType: 'arraybuffer'
            });

            const blob = new Blob([response.data], { type: 'image/png' });
            setQrCodeImage(URL.createObjectURL(blob));
            setLoadingCode(false)
        } catch (error) {
            console.error('Failed to generate QR code', error);
            notification['error']({message: "QRCode cannot be loaded right now. Please try again later!"})
        }
    };

    return (
        <div>
            <div style={{width: '30%', display:'inline-block'}}>
                <Form layout={'vertical'} initialValues={{"backColor": '#FFFFFF', 'primColor': '#000000', 'secColor': '#000000'}}>
                    <i>Place the QRCode on all tables throughout the establishment for quick access to your business information and menu.</i>
                    <br/>
                    <br/>

                    <Form.Item label="Business location">
                        <Select defaultValue={user.ownedBusinesses[0].slug} onChange={setSelectedBusiness}>
                            {user.ownedBusinesses.map(el => <Option key={el.slug} value={el.slug}>{el.name}</Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Style">
                        <Select defaultValue="square" onChange={setDrawer}>
                            <Option value="square">Square</Option>
                            <Option value="gapped_square">Gapped Square</Option>
                            <Option value="vertical">Vertical</Option>
                            <Option value="horizontal">Horizontal</Option>
                            <Option value="rounded">Rounded</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Color configuration">
                        <Select defaultValue="radial" onChange={setColorMask}>
                            <Option value="radial">Radial</Option>
                            <Option value="horizontal">Horizontal</Option>
                            <Option value="vertical">Vertical</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={'backColor'} label="Background Color" help={'Using a bright background color would make it easier for smartphones to recognize and scan the code.'}>
                        <ColorPicker
                            disabledAlpha
                            color={backColor}
                            onChangeComplete={(color) => setBackColor(color.toRgbString())}
                            presets={[{
                                label: 'Recommended',
                                colors: ['#FFFFFF','#edf1fe','#bfefff','#efe7df','#fdd6e5']
                            }]}
                        />
                    </Form.Item>
                    <br/>

                    <Form.Item label="Primary Color" name={'primColor'} style={{display:'inline-block', width:'50%'}}>
                        <ColorPicker disabledAlpha color={primColor} onChangeComplete={(color) => setPrimColor(color.toRgbString())} presets={[{
                            label: 'Recommended',
                            colors: ['#000000','#262b2f','#00626f','#373e02','#062e03','#b4262a','#280137']
                        }]}/>
                    </Form.Item>

                    <Form.Item label="Secondary Color" name={'secColor'} style={{display:'inline-block', width:'50%'}}>
                        <ColorPicker disabledAlpha color={secColor} onChangeComplete={(color) => setSecColor(color.toRgbString())} presets={[{
                            label: 'Recommended',
                            colors: ['#000000','#262b2f','#00626f','#373e02','#062e03','#b4262a','#280137']
                        }]}/>
                    </Form.Item>
                    <br/>
                    <i>We recommend incoporating this QRCode with your logo, and additional text instructing the user to scan the code!</i>
                    <br/>
                    <br/>
                    <Button type={'primary'} href={qrCodeImage} download>
                        Download code
                    </Button>
                </Form>
            </div>
            {qrCodeImage && (
                <div style={{width: '60%', display:'inline-block', verticalAlign: 'top', marginLeft:'10%'}}>
                    {loadingCode ? <Spin size={'large'} style={{marginLeft:'40%', marginTop:'40%'}}/> : <img src={qrCodeImage} alt="QR Code" />}
                </div>
            )}
        </div>
    );
};

export default QrCodeGenerator;
