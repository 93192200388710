import React, {useEffect} from 'react'
import {Breadcrumb, Button, Card, Typography} from "antd";
import {Link} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import {Loading} from "../../App";

const PlanMap = {
    0: "Free",
    1: "Growth",
    2: "Pro",
    3: "Unlimited"
}

const Settings = ({user, loading}) => {
    useEffect(()=>{
        console.log(user)
    },[])
    return (
        <div style={{textAlign:'start', padding:'20px'}}>
            <Breadcrumb
                items={[
                    {
                        title: <Link to="/dashboard"><HomeOutlined/></Link>,
                    },
                    {
                        title: "Account Settings"
                    },
                ]}
            />
            <Typography.Title level={2} style={{margin:'10px 0'}}>Account Settings</Typography.Title>
            <br/>
            {loading ? <Loading/> :
                <div>
                    <Card>
                        <Typography.Text style={{fontWeight: 'bold'}}>Email</Typography.Text>
                        <br/>
                        <Typography.Text>{user.email}</Typography.Text>
                        <br/><br/>
                        <Typography.Text style={{fontWeight: 'bold'}}>Plan</Typography.Text>
                        <br/>
                        <Typography.Text>{PlanMap[user.plan]}</Typography.Text>
                        <Button type={'link'} href={'/contact'} style={{textDecoration: 'underline'}}>Contact us to change your plan</Button>
                        <br/><br/>
                        <Typography.Text style={{fontWeight: 'bold'}}>Registered Businesses</Typography.Text>
                        <br/>
                        <Typography.Text>{user.ownedBusinesses.length}</Typography.Text>
                        <br/><br/><br/>
                        <Button type={'link'} href={'/contact'} style={{textDecoration: 'underline', color: 'gray', padding:0}}>Delete all user data</Button>
                    </Card>
                </div>}
        </div>
    )
}

export default Settings