import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Image, Popconfirm, Row, Tag, Tooltip, Typography} from 'antd';
import {DeleteOutlined, EditOutlined, EllipsisOutlined} from '@ant-design/icons';
import foodStock from '../../../assets/foodStock.png'
import axios from '../../../axios_conf'
import {useNavigate} from "react-router-dom";

const { Text, Title } = Typography;

export const currencyMap = (currency) => {
    const mp = {
        "USD": "$",
        "EUR": "€",
        "DEN": " ден.",
        "DIN": " din.",
        "YEN": "¥"
    }
    console.log(currency)
    return mp[currency]
}
const MenuItemCard = ({
                          photoUrl: picture,
                          title,
                          description,
                          tags,
                          allergens,
                          originalPrice,
                          currentPrice,
                          businessIds,
                          user,
                          id,
                          deleted,
                          client,
                          currency
                      }) => {
    const truncatedTitle = title.length > 60 ? title.slice(0, 60) + '...' : title;
    const truncatedDescription = description==null ? "" :
        description.length > 200 ? description.slice(0, 200) + '...' : description;

    const [confirmLoading, setConfirmLoading] = useState(false);
    const navigate = useNavigate();

    const renderPrice = () => {
        if (currentPrice && currentPrice < originalPrice) {
            const originalPriceFormatted = originalPrice;
            const discountedPriceFormatted = currentPrice;

            return (
                <div>
                    <Text delete strong>
                        {originalPriceFormatted}{currencyMap(currency)}
                    </Text>
                    <br/>
                    <Text type="danger" strong>
                        {discountedPriceFormatted}{currencyMap(currency)}
                    </Text>
                </div>
            );
        }

        return <Text strong>{originalPrice}{currencyMap(currency)}</Text>;
    };

    const deleteItem = () => {
        setConfirmLoading(true)
        axios.delete("Menu/"+id).then(res=>{
            deleted()
            setConfirmLoading(false)
        })
    }

    return (
        <Card style={{ width: '100%', marginBottom:'2px' }}>
            <Row align="middle" gutter={[16, 16]}>
                {picture!="" ?
                <Col xs={24} sm={8} lg={6} xl={4}>
                    <div style={{ position: 'relative', paddingTop: '100%', overflow: 'hidden' }}>
                        {picture!="" ?
                            <img
                                src={picture}
                                alt="Menu Item"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                            :
                            <img
                                src={foodStock}
                                alt="Menu Item"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        }
                    </div>
                </Col> : <></>}
                <Col xs={24} sm={picture!="" ? 16: 24} lg={picture!="" ? 18: 24} xl={picture!="" ? 20: 24}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ marginBottom: '8px' }}>
                            {tags.map((tag) => (
                                <Tag key={tag}>{tag}</Tag>
                            ))}
                        </div>
                        <Row align="middle">
                            <Col xs={20} sm={20} lg={22} xl={22}>
                                <Typography.Title level={5} style={{ fontWeight: 'bold', margin: '0' }}>{truncatedTitle}</Typography.Title>
                            </Col>
                            <Col xs={4} sm={4} lg={2} xl={2}>
                                {renderPrice()}
                            </Col>
                        </Row>
                        <Typography.Text type={'secondary'} style={{ marginTop: '8px' }}>{truncatedDescription}</Typography.Text>
                        {allergens.length > 0 ?
                            <div style={{ marginTop: '8px' }}>
                                <span style={{ fontWeight: 'bold' }}>Allergens: </span>
                                {allergens.join(', ')}
                            </div>
                            :
                            <></>
                        }
                    </div>
                </Col>
            </Row>
            {!client ? <div>
                <hr/>
                <Button icon={<EditOutlined />} type={'link'} onClick={()=>navigate("/dashboard/add-menu?id="+id)}>Edit</Button>
                <Popconfirm
                    title="Delete this menu-item"
                    description="Are you sure to delete this menu-item? This item will be deleted from all business locations"
                    onConfirm={deleteItem}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ loading: confirmLoading }}
                >
                    <Button danger type={'text'} icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>
                <Tooltip title={user.ownedBusinesses.filter(x => businessIds.includes(x.id)).map(x => x.name).join(', ')}>
                    <u>Active in {businessIds.length} locations</u>
                </Tooltip>
            </div> : <></> }
        </Card>
    );
};

export default MenuItemCard;






