import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button, Form, Input, Result, Spin} from "antd";
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import axios from "../../axios_conf";

const ResetPassword = ({}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [navState, setNavState] = useState("email")
    const [id, setId] = useState(null)
    const navigate = useNavigate()

    useEffect(()=>{
        const retId = searchParams.get("id")
        setId(retId)
        setNavState('loading')
        if(retId!=null){
            axios.get("/Users/reset/validate", {params:{resetId: retId}}).then(el=>{
                if(!el.data){
                    setNavState('error')
                    setId(null)
                }
                else {
                    setNavState('newPassword')
                }
            }).catch(er=>{
                setNavState('error')
                setId(null)
            })
        }
        else{
            setNavState("email")
        }
    },[])

    const sendEmail = (data) => {
        setNavState('loading')
        axios.post("/Users/reset", {}, {params:{email:data.email}}).then(el=>{
            setId(null)
            setNavState("emailSent")
        }).catch(res=>{
            setNavState("error")
            setId(null)
        })
    }

    const resetPassword = (data) => {
        setNavState('loading')
        axios.post("/Users/reset/new", {}, {params:{password:data.password, resetId: id}}).then(el=>{
            setNavState("success")
        }).catch(res=>{
            setNavState("error")
            setId(null)
        })
    }
    return(
        <div style={{height:'100vh'}}>
            {navState=="loading" ? <Spin className={'center'} size={'large'}/>
                : navState == "email" ?
                    <Result
                        className={'center'}
                        icon={<LockOutlined />}
                        title="Reset your password"
                        extra={
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={sendEmail}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your Email!' }, {type: 'email', message: 'Please input a valid email!'}]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Send
                                    </Button>
                                </Form.Item>
                            </Form>
                        }
                    />
                    : navState == "emailSent" ?
                        <Result
                            className={'center'}
                            icon={<MailOutlined />}
                            title="Email sent"
                            subTitle="We have sent a email with a link to reset your email"
                            extra={<Button onClick={()=>setNavState("email")}>No email?</Button>}
                        />
                        : navState == "newPassword" ?
                            <Result
                                className={'center'}
                                icon={<LockOutlined />}
                                title="Reset your password"
                                extra={
                                    <Form
                                        name="normal_login"
                                        className="login-form"
                                        onFinish={resetPassword}
                                    >
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (value.length>=8) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Your password should be at least 8 characters'));
                                                    },
                                                }),
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />}/>
                                        </Form.Item>

                                        <Form.Item
                                            name="confirm"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password placeholder="Confirm Password" prefix={<LockOutlined className="site-form-item-icon" />}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="login-form-button">
                                                Send
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                }
                            />
                            : navState=='success' ?
                                <Result
                                    className={'center'}
                                    status="success"
                                    title="Your password has been reset!"
                                    extra={<Button type="primary" onClick={()=>navigate("/auth/login")}>To login</Button>}
                                />
                                :
                                <Result
                                    className={'center'}
                                    status="error"
                                    title="An error occurred while trying to reset your password"
                                    extra={<Button onClick={()=>setNavState("email")}>Try again</Button>}
                                />
            }

        </div>
    )
}
export default ResetPassword;