import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button, Result, Spin} from "antd";
import {MailOutlined} from "@ant-design/icons";
import axios from "../../axios_conf";

const Verify = ({}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [id, setId] = useState(null)
    const navigate = useNavigate()

    useEffect(()=>{
        const retId = searchParams.get("id")
        setId(retId)
        setLoading(true)
        if(retId!=null){
            axios.post("/Users/verify", {}, {params:{id: retId}}).then(el=>{
                setLoading(false)
            }).catch(er=>{
                setError(true)
                setLoading(false)
            })
        }
    },[])

    const resend = () => {
        setLoading(true)
        axios.post("/Users/verify/resend").then(el=>{
            setLoading(false)
            setId(null)
            navigate("/auth/verify")
        })
    }
    return(
        <div style={{height:'100vh'}}>
            {id==null ?
                <Result
                    className={'center'}
                    icon={<MailOutlined />}
                    title="Confirm your email"
                    subTitle="We have sent a verification mail to your address! Please also check the spam folder"
                    extra={<Button onClick={resend}>Resend</Button>}
                />
                :
                    loading ?
                        <Spin className={'center'} size={'large'}/>
                        :
                        !error ?
                            <Result
                                className={'center'}
                                status="success"
                                title="Your email has been verified!"
                                extra={<Button type="primary" onClick={()=>navigate("/dashboard")}>To dashboard</Button>}
                            />
                            :
                            <Result
                                className={'center'}
                                status="error"
                                title="An error occurred while trying to verify your email"
                                extra={<Button onClick={resend}>Resend</Button>}
                            />
            }
        </div>
    )
}

export default Verify;