import React from 'react';
import { Card, Typography, List } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Text } = Typography;

const CategoryList = ({ categories, onCategoryOrderChange }) => {
    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const { source, destination } = result;
        onCategoryOrderChange(source.index, destination.index);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Typography.Text type={'secondary'}>Drag and drop the categories in your desired order.</Typography.Text>
            <br/>
            <br/>
            <Droppable droppableId="categoryList" key={'categoryList'}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <List
                            dataSource={categories}
                            renderItem={(category, index) => (
                                <Draggable draggableId={category.id.toString()} index={index} key={category.id}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Card style={{ marginBottom: '8px' }}>
                                                <Text>{category.name}</Text>
                                            </Card>
                                        </div>
                                    )}
                                </Draggable>
                            )}
                        />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default CategoryList;
